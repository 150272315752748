import type {ReactNode} from 'react';
import clsx from 'clsx';

import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

type Props = {
	children: ReactNode;
};

export const Blockquote = ({children}: Props) => {
	return (
		<blockquote className={clsx('my-10', 'pl-8', 'font-semibold', 'relative')}>
			<span
				aria-hidden="true"
				className={clsx(
					'text-black',
					'text-[10rem]',
					'leading-none',
					'absolute',
					'-z-10',
					'-top-10',
					'left-0',
					'opacity-10',
				)}
			>
				“
			</span>
			<Paragraph>{children}</Paragraph>
		</blockquote>
	);
};
